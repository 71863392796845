<template>
    <div class="page-explore-results hero-wrapper">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Datasets matching the taxonomy string</h1>
                <strong 
                    @click="copyToClipboard" 
                    class="url-text" 
                    style="font-size: 20px; cursor: pointer;"
                    >{{ url }}</strong>
                    
                    <span v-if="copied" class="copied-message">Copied!</span>
            </div>

            <div class="column is-12 box" v-if="!isMobile()">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Dataset Title</th>
                            <th>Dataset Facility</th>
                            <th>Year of Experiment</th>
                        </tr>
                    </thead>
                    <GenericProjectItem 
                        v-for="project in projects" 
                        v-bind:key="project.id" 
                        v-bind:project="project" 
                        v-bind:page="'home'"/>
                    <tbody>

                    </tbody>
                </table>
            </div>

            <ProjectBox v-else v-for="project in projects" v-bind:key="project.id" v-bind:project="project" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import { isMobile } from '@/assets/methods.js'
import GenericProjectItem from '@/components/GenericProjectItem'
import ProjectBox from '@/components/ProjectBox'

export default {
    name: "ExploreResults",
    components: {
        GenericProjectItem,
        ProjectBox,
    },
    data() {
        return {
            projects: [],
            storedTaxSearch: '',
            url: '',
            copied: false,
        }
    },
    created() {
        this.storedTaxSearch = sessionStorage.getItem('taxonomySearchKey')
    },
    mounted() {
        document.title = "Explore Datasets | " + this.$store.state.appName

        let uri = window.location.search.substring(1)

        this.url = window.location.href

        let params = new URLSearchParams(uri)

        if (params.get('tax')) {
            this.query = params.get('tax')

            this.searchByTaxonomy(params.get('tax'))
        }

    },
    methods: {
        isMobile,
        copyToClipboard() {
            navigator.clipboard.writeText(this.url).then(() => {
                this.copied = true;
                setTimeout(() => {
                this.copied = false;
                }, 2000);
            });
        },
        async searchByTaxonomy(taxToSearch) {
            if (taxToSearch === this.storedTaxSearch) {
                this.projects = JSON.parse(sessionStorage.getItem('exploredProjects'))

            } else {
                this.$store.commit('setIsLoading', true)

                if (this.projects.length === 0) {

                    await axios
                        .post('/api/v1/datasets/explore-results/', { 'tax': this.query })
                        .then(response => {
                            this.projects = response.data

                            sessionStorage.setItem('exploredProjects', JSON.stringify(this.projects))
                            sessionStorage.setItem('taxonomySearchKey', taxToSearch)

                        })
                        .catch(error => {
                            console.log(error)
                        })
                }


                this.$store.commit('setIsLoading', false)
            }
        }
    }
}
</script>

<style>
.url-text:hover {
    background-color: #DBEAFE;
}

.copied-message {
    margin-top: 8px;
    color: #38A935;
    font-weight: 500;
}
</style>