<template>
  <div class="page-facility hero-wrapper">
    <div class="columns is-multiline">
      <div class="column is-12 my-6">
        <div
          class="is-flex is-flex-direction-column-reverse is-hidden-tablet"
        >
          <h2 class="is-size-2">{{ facility.name }}</h2>

          <figure
            v-if="facility.get_logo"
            class="image mb-2 has-text-centered"
          >
            <img
              loading="lazy"
              v-bind:src="facility.get_logo"
              v-bind:alt="facility.name"
              decoding="async"
              style="max-height: 3.5rem; width: auto"
            />
          </figure>
        </div>

        <div
          class="is-flex is-align-items-center is-justify-content-space-between is-hidden-mobile"
          style="position: relative"
        >
          <h2 class="is-size-2">{{ facility.name }}</h2>

          <figure
            v-if="facility.get_logo"
            class="image ml-4"
            style="position: absolute; right: 0"
          >
            <img
              loading="lazy"
              v-bind:src="facility.get_logo"
              v-bind:alt="facility.name"
              decoding="async"
              style="max-height: 3.5rem; width: auto"
            />
          </figure>
        </div>

        <h3
          v-if="facility.address"
          class="is-size-8 has-text-left"
        >
          <Icon icon="humbleicons:location" />
          {{ facility.address }}
        </h3>

        <div
          v-if="facility.website"
          class="is-size-8 has-text-left"
        >
          <Icon icon="humbleicons:url" />
          <a
            :href="facility.website"
            style="text-decoration: underline"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ facility.website }}
          </a>
        </div>

        <div
          v-if="description"
          class="my-4"
        >
          <div
            class="is-size-10 has-text-grey"
            v-html="description"
          ></div>
        </div>
      </div>

      <div
        class="column is-12 box"
        v-if="!isMobile()"
      >
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Dataset Title</th>
              <th>Year of Experiment</th>
            </tr>
          </thead>
          <GenericProjectItem
            v-for="project in facility.facility_datasets"
            v-bind:key="project.id"
            v-bind:project="project"
            v-bind:page="'Facility'"
          />
          <tbody></tbody>
        </table>
      </div>

      <ProjectBox
        v-else
        v-for="project in facility.facility_datasets"
        v-bind:key="project.id"
        v-bind:project="project"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import { marked } from "marked";

import { isMobile, getLocalStorage, sort } from "@/assets/methods.js";
import GenericProjectItem from "@/components/GenericProjectItem";
import ProjectBox from "@/components/ProjectBox";
import { Icon } from '@iconify/vue';

export default {
  name: "Facility",
  components: {
    GenericProjectItem,
    ProjectBox,
    Icon,
  },
  data() {
    return {
      facility: {
        facility_datasets: [],
      },
      description: "",
    };
  },
  mounted() {
    this.getFacility();
  },
  methods: {
    isMobile,
    async getFacility() {
      this.$store.commit("setIsLoading", true);

      const facility_slug = this.$route.params.facility_slug;

      var datasets = getLocalStorage("datasets");

      if (datasets !== null) {
        this.facility.facility_datasets = datasets.filter((dataset) =>
          dataset.experiment_facility.find(
            (facility) => facility.slug === facility_slug
          )
        );

        var facility =
          this.facility.facility_datasets[0].experiment_facility.find(
            (facility) => facility.slug === facility_slug
          );

        sort(this.facility.facility_datasets);

        this.facility = { ...this.facility, ...facility };

      } else {
        await axios
          .get(`/api/v1/facility/${facility_slug}`)
          .then((response) => {
            this.facility = response.data;
            sort(this.facility.facility_datasets);
          })
          .catch((error) => {
            console.log(error);

            toast({
              message: "Something went wrong. Please try again",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          });
      }

      if (this.facility.description) {
        this.description = marked(this.facility.description);
      }

      document.title = this.facility.name + " | " + this.$store.state.appName;
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
