<template>
    <div class="page-world-map hero-wrapper">
        <div class="column is-12">
            <h1 class="title">Explore Datasets Geographically</h1>
        </div>

        <div id='svgMap'></div>
        <!-- <WorldMap /> -->
    </div>
</template>

<script>
import axios from 'axios'
import { getLocalStorage, setLocalStorage } from '@/assets/methods.js'

// import WorldMap from '@/components/WorldMap'
import svgMap from '../assets/svgMap'
import '../assets/styles/svgMap.min.css'


export default {
    name: "WorldMap",
    components: {
        // WorldMap,
    },
    data() {
        return {
            mySvgMap: "",
        }
    },
    mounted() {
        document.title = 'World Map | ' + this.$store.state.appName

        this.getDatasets()

    },
    methods: {
        async getDatasets() {
            this.$store.commit('setIsLoading', true)

            let datasets = getLocalStorage("datasets")

            if (datasets === null) {
                // Query for datasets
                await axios
                    .get('/api/v1/all-datasets')
                    .then(response => {
                        datasets = response.data
                        setLocalStorage("datasets", datasets)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }

            this.disaggregateDatasets(datasets)

            this.$store.commit('setIsLoading', false)
        },
        disaggregateDatasets(datasets) {

            const regionNames = new Intl.DisplayNames(
                ['en'], { type: 'region' }
            );

            let datasetsDisaggregated = {};

            datasets.forEach(function (dataset) {

                dataset.experiment_facility.forEach(function (facility) {
                    let country = facility.country
                    let facilityName = facility.name

                    if (datasetsDisaggregated[country]) {
                        datasetsDisaggregated[country]["facility"].add(facilityName)
                        datasetsDisaggregated[country]["dataset"]++

                    } else {
                        datasetsDisaggregated[country] = {
                            "facility": new Set([facilityName]),
                            "dataset": 1,
                            // "link": "/country/" + regionNames.of(country)
                            "link": "/country/" + country
                        }

                    }

                })
            })

            for (const key in datasetsDisaggregated) {
                if (datasetsDisaggregated.hasOwnProperty(key)) {
                    const innerData = datasetsDisaggregated[key].facility;
                    datasetsDisaggregated[key].facility = innerData.size;
                }
            }

            var mySvgMap = new svgMap({
                targetElementID: 'svgMap',
                colorMax: '#4f1900',
                colorMin: '#4f1900',
                colorNoData: '#00394f',
                flagType: 'image',
                showZoomReset: true,
                data: {
                    data: {
                        facility: {
                            name: 'Number of Facilities',
                            thousandSeparator: ',',
                        },
                        dataset: {
                            name: 'Number of Datasets',
                        }
                    },
                    applyData: 'facility',
                    values: datasetsDisaggregated
                }
            });

            this.mySvgMap = mySvgMap;

        },
    },
}
</script>
