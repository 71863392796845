<template>
  <div class="page-web-service hero-wrapper">
    <div class="content">
      <div id="swagger-container"></div>
    </div>
  </div>
</template>

<script>
import SwaggerUI from "swagger-ui-dist/swagger-ui-es-bundle";
import "swagger-ui-dist/swagger-ui.css";

export default {
  props: {
    specUrl: {
      type: String,
      required: true,
    },
  },
  mounted() {
    SwaggerUI({
      dom_id: "#swagger-container",
      url: this.specUrl,
    });

    this.applyCustomStyles();
  },
  methods: {
    applyCustomStyles() {
      const swaggerContainer = document.querySelector("#swagger-container");
      if (swaggerContainer) {
        swaggerContainer.classList.add("swagger-custom");
      }
    },
  },
};
</script>

<style >
.content {
  margin: 0 auto;
  font-family: "Montserrat", sans-serif !important;
  line-height: 1.5rem;
  font-size: 16px;
}

/* Override Swagger UI styles */
.swagger-custom {
  font-family: "Montserrat", sans-serif !important;
}

/* Ensure text in Swagger UI components follows content styling */
.swagger-custom * {
  font-family: "Montserrat", sans-serif !important;
}

#swagger-container .swagger-ui .info .title small pre {
  color: white !important;
  background-color: transparent !important;
  font-weight: bold;
  overflow: hidden !important;
  width: 100% !important;
  height: 100% !important;
}
#swagger-container .swagger-ui .info .version-stamp {
  background-color: #38A935 !important;
  color: white !important;
  border-radius: 5px;
  padding: 3px 8px;
}

#swagger-container .swagger-ui .scheme-container {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
</style>
